<template>
  <v-card :height="height">
    <div class="lottie-welcome">
      <Lottie
        :options="{
          animationData: CatRebahan,
          loop: true,
        }"
      />
    </div>
    <div class="pa-4 d-flex flex-column align-start slide-height">
      <div class="d-flex align-center mb-4">
        <v-img
          :src="require('@/assets/images/svg/logo.png')"
          max-width="32px"
          height="32px"
          class="mr-2"
          alt="logo"
          contain
          eager
        />
        <v-img
          :src="require('@/assets/images/svg/oriens-text.svg')"
          max-width="120px"
          alt="logo"
          contain
          eager
        />
      </div>
      <span class="text-subtitle-1 d-block font-medium primary--text">
        Halo, {{ $store.state.auth.userData.first_name + ' ' + $store.state.auth.userData.last_name }}
      </span>
      <span class="text-subtitle-2 d-block">
        Selamat datang di OriensCRM!
      </span>
      <!-- <v-btn
            color="primary"
            outlined
            class="mt-auto"
            small
            text
          >
            Lihat Tour
          </v-btn> -->
    </div>
    <!-- <swiper-slide>
        <div class="pa-4 d-flex flex-column align-start slide-height">
          <span class="text-subtitle-1 primary--text font-medium d-block">
            Maksimalkan OriensCRM
          </span>
          <span class="text-subtitle-2 mt-2">
            Lihat cara bagaimana memaksimalkan fitur dan kapabilitas OriensCRM. 🚀
          </span>
          <v-btn
            color="primary"
            outlined
            class="mt-auto"
            small
            text
          >
            Lihat Tutorial
          </v-btn>
        </div>
      </swiper-slide> -->
    <!-- <swiper-slide>
        <div class="pa-4 d-flex flex-column align-start slide-height">
          <span class="text-subtitle-1 font-medium primary--text">
            Lihat Yang Baru
          </span>
          <span class="text-subtitle-2 mt-2">
            Lihat apa yang baru dari OriensCRM 😎
          </span>
          <v-btn
            color="primary"
            outlined
            class="mt-auto"
            small
            text
          >
            Lebih Lanjut
          </v-btn>
        </div>
      </swiper-slide> -->
  </v-card>
</template>

<script>
import Lottie from 'vue-lottie'
import CatRebahan from '@/assets/lottie/cat_rebahan.json'
import AdminLottie from '@/assets/lottie/admin.json'
import 'swiper/css/swiper.css'

export default {
  components: {
    Lottie,
  },
  props: {
    lottie: {
      type: String,
      default: 'cat',
    },
    height: {
      type: String,
      default: '200px',
    },
  },
  setup() {
    const swiperOption = {
      speed: 800,
      direction: 'vertical',
      slidesPerView: 1,
      mousewheel: true,
      spaceBetween: 30,
      autoplay: {
        delay: 10000,
        disableOnInteraction: false,
      },
      loop: false,
      centeredSlides: true,
    }

    return {
      swiperOption,
      CatRebahan,
      AdminLottie,
    }
  },
}
</script>

<style lang="scss">
.lottie-welcome {
  position: absolute;
  bottom: -20px;
  right: 0px;
  height: 80%;
  transform: scale(1.4);
  overflow: hidden;
}

.slide-height {
  height: calc(100% - 30px) !important;
}
</style>
